  const select = () => {
    const selects = document.querySelectorAll('.js-select');
  
    selects.forEach((select) => {
      const btn = select.querySelector('.js-select-btn');
      const text = select.querySelector('.js-select-text');
      const options = select.querySelectorAll('.js-select-option');
  
      const close = () => {
        select.classList.remove('active');
      };
  
      const open = () => {
        select.classList.add('active');
      };
  
      btn.addEventListener('click', (e) => {
        const isActive = select.classList.contains('active');
  
        if(isActive){
          close();
        }else {
          open();
        }
      });
  
      options.forEach((option) => {
        option.addEventListener('click', () => {
          const value = option.innerText;
          text.innerText = value;
  
          close();
        });
      });
  
  
      document.documentElement.addEventListener('click', function (event) {
      	var isClickWithinOpenedDiv = top_walker(event.target, function (node) {
      		return node === select
      	});
      	if (isClickWithinOpenedDiv) {
  
      	}else{
          close();
        }
      }, true);
    });
  
  
  };
  
  const mainSlider = () => {
    const sliders = document.querySelectorAll('.js-main-slider');
  
    sliders.forEach((slider) => {
      const options = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 400,
        prevArrow: '<button type="button" class="map-arrow map-arrow--left" tabindex="0"><svg class="icon icon-circle-arrow-left arrow__icon"><use xlink:href="#circle-arrow-left"></use></svg></button>',
        nextArrow: '<button type="button" class="map-arrow map-arrow--right" tabindex="0"><svg class="icon icon-circle-arrow-right arrow__icon"><use xlink:href="#circle-arrow-right"></use></svg></button>',
      };
  
      $(slider).slick(options);
    });
  };
  
  const rangeSlider = () => {
    const sliders = document.querySelectorAll('.js-range-slider');
    const totalPrices = document.querySelectorAll('.js-build-price');
    let summArray = [];
    let summ = 0;
  
    const updateInfo = (slider, type, lexicon) => {
      const numberNodes = document.querySelectorAll(`.js-${type}-number`);
      const keyNodes = document.querySelectorAll(`.js-${type}-key`);
      const activeItem = slider.querySelector('.js-li.active');
      const currentValue = activeItem.querySelector('.js-item').innerText;
  
      numberNodes.forEach((numberNode) => {
        numberNode.innerText = currentValue;
      });
  
      keyNodes.forEach((keyNode) => {
        const keyText = declOfNum(currentValue, lexicon);
        keyNode.innerText = keyText;
      });
    };
  
    const makeCircles = (slider) => {
      const isInet = slider.classList.contains('js-range-slider-inet');
      const isTv = slider.classList.contains('js-range-slider-tv');
      const isInetTv = slider.classList.contains('js-range-slider-tv-inet');
  
      const buildCircles = (circle, slider, type, lexiconArray) => {
        const circleNode = document.querySelector(`.${circle}`);
        const liItems = slider.querySelectorAll('.js-li');
        let activePercent = 0;
  
        // get active percent
        liItems.forEach((liItem, index, array) => {
          const isActive = liItem.classList.contains('active');
  
          if(isActive){
            const totalNumber = array.length - 1;
            const currentIndex = index;
  
            activePercent = getPercentNumberFromNumber(totalNumber, currentIndex);
          }
        });
  
        const fillPercent = getPercent(360, activePercent);
        DAG.changeCircle(circleNode, fillPercent);
        updateInfo(slider, type, lexiconArray);
      };
  
      if(isInet){
        buildCircles('js-circle-inet', slider, 'inet', ['Мбит/с', 'Мбит/с', 'Мбит/с']);
      }else if(isTv){
        buildCircles('js-circle-tv', slider, 'tv', ['Канал', 'Канала', 'Каналов']);
      }else if(isInetTv){
        buildCircles('js-circle-inet-tv', slider, 'inet-tv', ['Канал', 'Канала', 'Каналов']);
      }
    };
  
    const calc = () => {
      const items = document.querySelectorAll('.js-li');
      summArray = [];
  
      items.forEach((item) => {
        const isActive = item.classList.contains('active');
  
        if(isActive){
          const dataPrice = parseInt(item.getAttribute('data-price'));
  
          summArray.push(dataPrice);
          const reducer = (accumulator, currentValue) => accumulator + currentValue;
          summ = summArray.reduce(reducer);
        }
      });
  
      totalPrices.forEach((totalPrice) => {
        totalPrice.innerText = summ;
      });
    };
  
    calc();
  
    sliders.forEach((slider, index) => {
      const btn = slider.querySelector('.js-btn');
      const bar = slider.querySelector('.js-bar');
      const items = slider.querySelectorAll('.js-li');
      let optionCoords = [];
  
  
      const getOptionCoords = () => {
        optionCoords = [];
  
        items.forEach((item) => {
          const offsetLeft = item.offsetLeft;
  
          item.setAttribute('data-left', offsetLeft);
          optionCoords.push(offsetLeft);
        });
      };
  
      const changeBtnPosition = () => {
        const slider = btn.closest('.js-range-slider');
        const barLeft = bar.offsetLeft;
        const barWidth = bar.getBoundingClientRect().width;
        let btnLeft = DAG.cursorX - barLeft;
  
        if(btnLeft > barWidth) {
          btnLeft = barWidth;
        }else if (btnLeft < 0) {
          btnLeft = 0;
        }
  
        const closest = optionCoords.reduce((a, b) => {
          return Math.abs(b - btnLeft) < Math.abs(a - btnLeft) ? b : a;
        });
  
        // add or remove "active"
        items.forEach((item) => {
          const itemLeft = item.getAttribute('data-left');
          const isActive = itemLeft == closest;
  
          if(isActive){
            item.classList.add('active');
          }else{
            item.classList.remove('active');
          }
        });
  
        btn.style.left = `${closest}px`;
        calc();
        makeCircles(slider);
      };
  
      const changeInitialPosition = () => {
        items.forEach((item) => {
          const isActive = item.classList.contains('active');
  
          if(isActive){
            const offsetLeft = item.offsetLeft;
            btn.style.left = `${offsetLeft}px`;
          }
        });
      };
  
      const btnChangePositionMobile = (e) => {
        const slider = btn.closest('.js-range-slider');
        const isInet = slider.classList.contains('js-range-slider-inet');
        const isTv = slider.classList.contains('js-range-slider-tv');
        const isInetTv = slider.classList.contains('js-range-slider-tv-inet');
        const touchClientX = e.touches[0].clientX;
        const barLeft = bar.offsetLeft;
        const barWidth = bar.getBoundingClientRect().width;
        let btnLeft = touchClientX - barLeft;
  
        if(btnLeft > barWidth) {
          btnLeft = barWidth;
        }else if (btnLeft < 0) {
          btnLeft = 0;
        }
  
        const closest = optionCoords.reduce((a, b) => {
          return Math.abs(b - btnLeft) < Math.abs(a - btnLeft) ? b : a;
        });
  
        // add or remove "active"
        items.forEach((item) => {
          const itemLeft = item.getAttribute('data-left');
          const isActive = itemLeft == closest;
  
          if(isActive){
            item.classList.add('active');
          }else{
            item.classList.remove('active');
          }
        });
  
        btn.style.left = `${closest}px`;
        calc();
  
        if(isInet){
          updateInfo(slider, 'inet', ['Мбит/с', 'Мбит/с', 'Мбит/с']);
        }else if(isTv){
          updateInfo(slider, 'tv', ['Канал', 'Канала', 'Каналов']);
        }else if(isInetTv){
          updateInfo(slider, 'inet-tv', ['Канал', 'Канала', 'Каналов']);
        }
      };
  
      // init
      getOptionCoords();
      changeInitialPosition();
      makeCircles(slider);
  
      // events
      items.forEach((item) => {
        item.addEventListener('click', () => {
          changeBtnPosition();
        });
      });
  
      btn.addEventListener('mousedown', () => {
        window.addEventListener('mousemove', changeBtnPosition);
        btn.classList.add('active');
      });
  
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', changeBtnPosition);
        btn.classList.remove('active');
      });
  
      btn.addEventListener('touchstart', () => {
        window.addEventListener('touchmove', btnChangePositionMobile, true);
        btn.classList.add('active');
      });
  
      btn.addEventListener('touchend', () => {
        window.removeEventListener('touchmove', btnChangePositionMobile, true);
        btn.classList.remove('active');
      });
    });
  };
  
  const tabs = () => {
  	const btns = document.querySelectorAll('[data-tabclass]');
  
    btns.forEach((btn) => {
      btn.addEventListener('click', () => {
        const contentData = btn.getAttribute('data-tabclass');
        const btnNumberData = btn.getAttribute('data-tabnumber');
        const contentNodes = document.querySelectorAll(`.${contentData}`);
        const btnSiblings = getSiblings(btn);
  
        // toggle btn class
        btnSiblings.forEach((btnSibling) => {
          btnSibling.classList.remove('active');
        });
  
        btn.classList.add('active');
  
        // toggle contentNodes
        contentNodes.forEach((contentNode) => {
          const items = Array.from(contentNode.children);
  
          items.forEach((item) => {
            const itemNumberData = item.getAttribute('data-tabnumber');
            const isNumberEqual = itemNumberData == btnNumberData;
  
            if(isNumberEqual) {
              const siblings = getSiblings(item);
  
              siblings.forEach((sibling) => {
                sibling.classList.remove('active');
              });
  
              item.classList.add('active');
            }
          });
        });
      });
    });
  };
  
  function pick () {
    const items = document.querySelectorAll('.js-pick');
  
    items.forEach(item => {
      const btn = item.querySelector('.js-pick-btn'),
            span = btn.querySelector('span');
  
      btn.addEventListener('click', event => {
        if (item.classList.contains('pick')) {
          item.classList.remove('pick');
          span.textContent = 'Выбрать';
        }
        else {
          item.classList.add('pick');
          span.textContent = 'Выбрано';
        }
      })
    })
  }
  const tripleSlider = () => {
    const sliders = document.querySelectorAll('.js-triple-slider');
  
    sliders.forEach((slider) => {
      const options = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 981,
            settings: {
              variableWidth: true,
              slidesToShow: 1,
            }
          }
        ]
      };
  
      $(slider).slick(options);
    });
  };
  
  const doubleSlider = () => {
    const sliders = document.querySelectorAll('.js-double-slider');
  
    sliders.forEach((slider) => {
      const options = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 2,
        responsive: [
          {
            breakpoint: 981,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      };
  
      $(slider).slick(options);
    });
  };
  
  const circleHover = () => {
    const circleHovers = document.querySelectorAll('.build__circle-hover');
    const fromMobile = window.matchMedia('(min-width: 981px)').matches;
  
      circleHovers.forEach((circleHover) => {
        const changeDescPosition = (e, elem) => {
          const isClassList = e.target.classList;
          
          if(isClassList){
            const isElem = e.target.classList.contains(`js-icon-${elem}`);
  
            if(isElem){
              const elem = e.target;
              const descData = elem.getAttribute('data-desc');
              const descNode = document.querySelector(`.${descData}`);
              const positionX = DAG.cursorX + 10;
              const positionY = DAG.cursorY + 20;
  
              // set desc position
              descNode.setAttribute('style', `left: ${positionX}px; top: ${positionY}px;`);
              descNode.classList.add('active');
  
              elem.addEventListener('mouseleave', () => {
                descNode.classList.remove('active');
              });
            }
          }
        };
  
        if(fromMobile){
          window.addEventListener('mousemove', function (e) {
            changeDescPosition(e, 'inet');
            changeDescPosition(e, 'tv');
            changeDescPosition(e, 'inet-tv');
          });
        }
    });
  };
  
  const search = () => {
    const searchNodes = document.querySelectorAll('.js-search');
  
    searchNodes.forEach((searchNode) => {
      const defaultNode = searchNode.querySelector('.js-default');
      const activeNode = searchNode.querySelector('.js-active');
      const openBtns = searchNode.querySelectorAll('.js-btn');
      const closeBtns = searchNode.querySelectorAll('.js-close-btn');
  
      const open = () => {
        searchNode.classList.add('active');
      };
  
      const close = () => {
        searchNode.classList.remove('active');
      };
  
      openBtns.forEach((openBtn) => {
        openBtn.addEventListener('click', () => {
          open();
        });
      });
  
      closeBtns.forEach((closeBtn) => {
        closeBtn.addEventListener('click', () => {
          close();
        });
      });
    });
  };
  
  const menu = () => {
    const btnsOpen = document.querySelectorAll('.js-menu-open');
    const btnsClose = document.querySelectorAll('.js-menu-close');
  
    btnsOpen.forEach((btnOpen) => {
      btnOpen.addEventListener('click', () => {
        const menu = document.querySelector('.js-menu');
        const isActive = menu.classList.contains('active');
  
        menu.classList.add('active');
        DAG.body.classList.add('overflow');
      });
    });
  
    btnsClose.forEach((btnClose) => {
      btnClose.addEventListener('click', () => {
        const menu = document.querySelector('.js-menu');
        const isActive = menu.classList.contains('active');
  
        menu.classList.remove('active');
        DAG.body.classList.remove('overflow');
      });
    });
  };
  
  const triangle = () => {
    const container = document.querySelector('.js-about-container');
    const triangle = document.querySelector('.js-about-triangle');
  
    const updateTriangleSize = (container, triangle) => {
      const containerInfo = getComputedStyle(container);
      const containerMarginRight = parseInt(containerInfo.marginRight);
      const containerPaddingRight = parseInt(containerInfo.paddingRight);
      const triangleWidth = (containerMarginRight + containerPaddingRight) / 10;
  
      triangle.setAttribute('style', `font-size: ${triangleWidth}rem;`);
    };
  
    updateTriangleSize(container, triangle);
  
    window.addEventListener('resize', () => {
      updateTriangleSize(container, triangle);
    });
  };
  
  const searchMenu = () => {
    const btnsOpen = document.querySelectorAll('.js-search-mobile-btn');
    const btnsClose = document.querySelectorAll('.js-search-close');
  
    btnsOpen.forEach((btnOpen) => {
      btnOpen.addEventListener('click', () => {
        const menu = document.querySelector('.js-search-popup');
        const isActive = menu.classList.contains('active');
  
        menu.classList.add('active');
        DAG.body.classList.add('overflow');
      });
    });
  
    btnsClose.forEach((btnClose) => {
      btnClose.addEventListener('click', () => {
        const menu = document.querySelector('.js-search-popup');
        const isActive = menu.classList.contains('active');
  
        menu.classList.remove('active');
        DAG.body.classList.remove('overflow');
      });
    });
  };
  
  const contentSlider = () => {
    const sliders = document.querySelectorAll('.js-slider-content');
  
    sliders.forEach((slider) => {
      const options = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        slidesToShow: 1,
        asNavFor: '.js-slider-bottom',
        adaptiveHeight: true
      };
  
      $(slider).slick(options);
    });
  };
  
  const bottomSlider = () => {
    const sliders = document.querySelectorAll('.js-slider-bottom');
  
    sliders.forEach((slider) => {
      const options = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 400,
        variableWidth: true,
        centerMode: true,
        asNavFor: '.js-slider-content',
        focusOnSelect: true
      };
      const section = slider.closest('.js-history');
  
      $(slider).slick(options);
  
      $(slider).slick('slickGoTo', 4);
      
      setTimeout(() => {
        section.classList.add('init');
      }, 400);
    });
  };
  
  (function () {
    function createZoom() {
      const zoomTemplate = document.querySelector('#zoom-map-template');
      const zoomElement = zoomTemplate.content.children[0].cloneNode(true);
  
      const ZoomLayout = ymaps.templateLayoutFactory.createClass(
        zoomElement.outerHTML,
        {
          /**
           * Redefining methods of the layout, in order to perform
           * additional steps when building and clearing the layout.
           */
          build: function () {
            // Calling the "build" parent method.
            ZoomLayout.superclass.build.call(this);
  
            /**
             * Binding handler functions to the context and storing references
             * to them in order to unsubscribe from the event later.
             */
            this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
            this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);
  
            // Beginning to listen for clicks on the layout buttons.
            $('#zoom-in').bind('click', this.zoomInCallback);
            $('#zoom-out').bind('click', this.zoomOutCallback);
          },
  
          clear: function () {
            // Removing click handlers.
            $('#zoom-in').unbind('click', this.zoomInCallback);
            $('#zoom-out').unbind('click', this.zoomOutCallback);
  
            // Calling the "clear" parent method.
            ZoomLayout.superclass.clear.call(this);
          },
  
          zoomIn: function () {
            var map = this.getData().control.getMap();
            map.setZoom(map.getZoom() + 1, { checkZoomRange: true });
          },
  
          zoomOut: function () {
            var map = this.getData().control.getMap();
            map.setZoom(map.getZoom() - 1, { checkZoomRange: true });
          }
        });
  
      return new ymaps.control.ZoomControl({
        options: {
          layout: ZoomLayout,
          position: {
            right: 40,
            top: 150,
          }
        }
      });
    }
  
    function createHubs(hubs, img) {
      return hubs.map((hub) => {
        const [MyBalloonLayout, _] = createHubBaloon(hub, '#pin-hub-template');
  
        return new ymaps.Placemark(
          hub['COORDINATES'],
          {
            iconCaption: img,
          },
          {
            iconLayout: 'default#image',
            iconImageHref: img,
            iconImageSize: [30, 30],
            iconImageOffset: [-15, -15],
            preset: 'islands#redDotIconWithCaption',
            balloonLayout: MyBalloonLayout,
            balloonPanelMaxMapArea: 0,
            hideIconOnBalloonOpen: false,
          }
        );
      })
    }
  
    function createHubBaloon(hub, template) {
      const pinHubTemplate = document.querySelector(template);
      const pinHubElement = pinHubTemplate.content.children[0].cloneNode(true);
      pinHubElement.closest('.contacts-map__block').classList.add(hub['STATUS'] === 'ONLINE' ? 'contacts-map__block--status-online' : 'contacts-map__block--status-offline');
      for (let key in hub) {
        const el = pinHubElement.querySelector('#' + key.toLowerCase());
        if (el) {
          el.textContent = hub[key];
        }
      }
      return createBaloon(pinHubElement.outerHTML, null, '.contacts-map__block');
    }
  
    function createPinBaloon(template) {
      const templateElement = document.querySelector(template);
      const element = templateElement.content.children[0].cloneNode(true);
      return createBaloon(element.outerHTML, null, '.contacts-map__block', DAG.popup);
    }
  
    function createBaloon(layout, contentLayout, layoutRootSelector, onBuild) {
      var MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
        layout,
        // Пример
        // '<div class="popover top' + (isCovered ? ' popover--success' : ' popover--error') + '">' +
        // '<a class="close" href="#">&times;</a>' +
        // '<div class="arrow"></div>' +
        // '<div class="popover-inner">' +
        // '$[[options.contentLayout observeSize minWidth=235 maxWidth=235 maxHeight=350]]' +
        // '</div>' +
        // '</div>',
        {
          /**
           * Строит экземпляр макета на основе шаблона и добавляет его в родительский HTML-элемент.
           * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#build
           * @function
           * @name build
           */
          build: function () {
            this.constructor.superclass.build.call(this);
            this._element = this.getParentElement().querySelector(layoutRootSelector);
            this.applyElementOffset();
            this.onCloseClick = this.onCloseClick.bind(this);
            this._element.querySelector('.js-baloon-close').addEventListener('click', this.onCloseClick);
            if (onBuild) {
              onBuild();
            }
          },
  
          /**
           * Удаляет содержимое макета из DOM.
           * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#clear
           * @function
           * @name clear
           */
          clear: function () {
            this._element.querySelector('.js-baloon-close').click();
  
            this.constructor.superclass.clear.call(this);
          },
  
          /**
           * Метод будет вызван системой шаблонов АПИ при изменении размеров вложенного макета.
           * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
           * @function
           * @name onSublayoutSizeChange
           */
          onSublayoutSizeChange: function () {
            MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);
  
            if (!this._isElement(this._element)) {
              return;
            }
  
            this.applyElementOffset();
            this.events.fire('shapechange');
          },
  
          /**
           * Сдвигаем балун, чтобы ".arrow" указывал на точку привязки.
           * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
           * @function
           * @name applyElementOffset
           */
          applyElementOffset: function () {
            this._element.style.left = -(this._element.offsetWidth / 2) + 'px';
            // -12 - магическое число. подобранно вручную. размер галочки .arrow 15px. по этому 12 выглядит симпатично.
            this._element.style.top = -(this._element.offsetHeight + this._element.querySelector('.contacts-map__block-arrow').offsetHeight + 10) + 'px';
          },
  
          /**
           * Закрывает балун при клике на крестик, кидая событие "userclose" на макете.
           * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
           * @function
           * @name onCloseClick
           */
          onCloseClick: function (e) {
            e.preventDefault();
            this.events.fire('userclose');
          },
  
          /**
           * Используется для автопозиционирования карты относительно облака сообщения (balloonAutoPan).
           * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ILayout.xml#getClientBounds
           * @function
           * @name getClientBounds
           * @returns {Number[][]} Координаты левого верхнего и правого нижнего углов шаблона относительно точки привязки.
           */
          // getShape: function () {
          //   if (!this._isElement(this._element)) {
          //     return MyBalloonLayout.superclass.getShape.call(this);
          //   }
          //
          //   var position = this._element.getBoundingClientRect();
          //
          //   return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
          //     [position.left, position.top],
          //     [
          //       position.left + this._element.offsetWidth,
          //       position.top + this._element.offsetHeight + this._element.querySelector('.contacts-map__block-arrow').offsetHeight
          //     ]
          //   ]));
          // },
  
          /**
           * Проверяем наличие элемента (в ИЕ и Опере его еще может не быть).
           * @function
           * @private
           * @name _isElement
           * @param [element] Элемент.
           * @returns {Boolean} Флаг наличия.
           */
          _isElement: function (element) {
            return !!(element && element.querySelector('.contacts-map__block-arrow'));
          }
        }
      );
  
      // Пример
      // Создание вложенного макета содержимого балуна.
      // if (contentLayout) {
      // var MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
      //   '<h3 class="popover-title">$[properties.balloonHeader]</h3>' +
      //   '<div class="popover-content">$[properties.balloonContent]</div>'
      // );
      // }
      // return [MyBalloonLayout, MyBalloonContentLayout];
  
      return [MyBalloonLayout, null];
    }
  
    /**
     * данные возвращаются в метрах
     */
    function getMinimalDistanceToHub(url, latitude, longitude) {
      return fetch(
        url,
        {
          method: 'POST',
          mode: 'no-cors',
          cache: 'no-cache',
          body: new URLSearchParams({
            LATITUDE: latitude,
            LONGITUDE: longitude,
            MODE: 'closest',
          }),
        }
      )
        .then((response) => {
          return response.status === 200 ? response.json() : { status: false, closest_nodes: [] };
        })
        .catch((error) => {
          console.log(error);
          return { status: false, closest_nodes: [] };
        });
    }
  
  
    function init(mapElement) {
      const center = mapElement.dataset.center.trim() ? JSON.parse(mapElement.dataset.center) : null;
      const bounds = mapElement.dataset.bounds.trim() ? JSON.parse(mapElement.dataset.bounds) : null;
      const hubImg = mapElement.dataset.hubimg.trim();
      const renderHubs = mapElement.dataset.renderhubs.trim().toLowerCase() === 'true';
      const pinImg = mapElement.dataset.pinimg.trim() || undefined;
      const pinSize = mapElement.dataset.pinsize.trim() ? JSON.parse(mapElement.dataset.pinsize) : [];
      const statusUrl = mapElement.dataset.statusurl.trim() || '';
      const zoom = parseInt(mapElement.dataset.zoom, 10) || 14;
  
      const button = document.querySelector('.contacts-map__search-button');
      const addressElement = document.querySelector('.contacts-map__search-input');
      if (addressElement) {
        const suggestView = new ymaps.SuggestView(addressElement);
        if (addressElement.value) {
          setTimeout(() => {
            button.click();
          }, 1000);
        }
      }
  
      if (button) {
        button.addEventListener('click', (evt) => {
          ymaps.geocode(addressElement.value).then(function (response) {
            var obj = response.geoObjects.get(0), error, hint;
            const bounds = obj.properties.get('boundedBy');
            const mapState = ymaps.util.bounds.getCenterAndZoom(
              bounds,
              [mapElement.clientWidth, mapElement.clientHeight],
            );
            const shortAddress = [obj.getAdministrativeAreas(), obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ');
            getMinimalDistanceToHub(statusUrl, mapState.center[0], mapState.center[1])
              .then((data) => {
                if (renderHubs) {
                  mapObject.geoObjects.removeAll();
                  createHubs(data.closest_nodes || [], hubImg).forEach((point) => {
                    mapObject.geoObjects.add(point);
                  })
                }
  
                mapObject.setCenter(mapState.center, mapState.zoom);
                mapObject.geoObjects.remove(placemark);
                placemark.geometry.setCoordinates(mapState.center);
                placemark.properties.set({
                  balloonStatus: data.status ? 'contacts-map__block--status-online' : 'contacts-map__block--status-offline',
                  balloonHeader: data.status ? 'Подключение возможно' : 'Подключение невозможно',
                  balloonContent: shortAddress,
                  // Расчет расстояния с указанием метрический единиц
                  // ymaps.formatter.distance(minimalDistanceToHub)
                  // При создании кастомного balloon можно использовать любые имена параметров.
                  // Следующие три определены в спецификации и поддерживаются официально
                  // balloonContentHeader: "Балун метки",
                  // balloonContentBody: "Содержимое <em>балуна</em> метки",
                  // balloonContentFooter: "Подвал",
                });
                const [balloonLayout, _] = createPinBaloon('#pin-template');
                placemark.options.set({
                  visible: true,
                  balloonShadow: false,
                  balloonLayout: balloonLayout,
                  // balloonContentLayout: MyBalloonContentLayout,
                  balloonPanelMaxMapArea: 0,
                  hideIconOnBalloonOpen: false,
                });
                mapObject.geoObjects.add(placemark);
                placemark.balloon.open();
  
  
  
                // Создаем круг
                const myCircle = new ymaps.Circle([
                  // Координаты центра круга
                  mapState.center,
                  // Радиус круга в метрах
                  200,
                ]);
  
                myCircle.options.set({
                  fillOpacity: 0.3,
                  outline: false
                });
  
                // Добавляем круг на карту
                mapObject.geoObjects.add(myCircle);
              });
          })
        });
      }
  
      const mapObject = new ymaps.Map("map", {
        center: bounds || center,
        zoom: zoom,
        controls: [
          // 'zoomControl',
          createZoom(),
        ]
      });
  
      const placemark = new ymaps.Placemark(
        mapObject.getCenter(),
        {
          iconCaption: pinImg,
        },
        {
          visible: !!bounds,
          iconLayout: 'default#image',
          iconImageHref: pinImg,
          iconImageSize: pinSize.length ? pinSize : undefined,
          iconImageOffset: pinSize.length ? [-pinSize[0] / 2, -pinSize[1] / 2] : undefined,
          preset: 'islands#redDotIconWithCaption',
        },
      );
  
      mapObject.options.set('scrollZoomSpeed', 0);
      mapObject.behaviors.disable('scrollZoom');
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        mapObject.behaviors.disable('drag');
      }
      mapObject.geoObjects.add(placemark);
    }
  
    const mapElement = document.querySelector('#map');
    if (window.ymaps && mapElement) {
      ymaps.ready(() => init(mapElement));
    }
  })();
  
  const accordion = () => {
    const btns = document.querySelectorAll('.js-accordion-btn');
  
    btns.forEach((btn) => {
      btn.addEventListener('click', (evt) => {
        evt.preventDefault();
        const parentItem = btn.closest('.js-accordion-item');
        const itemSiblings = getSiblings(parentItem);
        const isActive = parentItem.classList.contains('active');
  
        // show/hide accordion-content
        itemSiblings.forEach((itemSibling) => {
          itemSibling.classList.remove('active');
        });
  
        if (isActive) {
          parentItem.classList.remove('active');
        } else {
          parentItem.classList.add('active');
        }
  
        // scroll to accordion-content
        const btnCoords = getCoords(btn);
        const scrollTop = btnCoords.top - 400;
  
        window.scroll({
          top: scrollTop,
          behavior: 'smooth'
        });
      });
    });
  };
  
  const closeCookies = () => {
    const closeButton = document.querySelectorAll('.js-close-cookies');
    const cookies = document.querySelector('.cookies');
  
    const ACTIVE = 'cookies__active';
  
    const showBlock = (block) => {
      if (block && !block.classList.contains(ACTIVE)) {
        block.classList.add(ACTIVE);
      }
    };
  
    const closeBlock = (block) => {
      if (block && block.classList.contains(ACTIVE)) {
        block.classList.remove(ACTIVE);
      }
    };
  
    showBlock();
  
    closeButton.forEach((button) => {
      button.addEventListener('click', function () {
        closeBlock(cookies);
      });
    });
  };
  
  const phoneMask = () => {
    $('.js-phone-mask').mask('+7 (000) 000-00-00');
  };
  
  const validateForm = () => {
    const form = $('form');
  
    form.parsley();
    form.submit((evt) => {
      //событие успешной валидации onSuccess
      evt.preventDefault();
    });
  };
  
  const connectSelect = () => {
    const isSelect = document.querySelectorAll('.js-connect-select').length != 0;
  
    if(isSelect){
      const select = document.querySelector('.js-connect-select');
      const options = select.querySelectorAll('.js-select-option');
      const section = select.closest('.js-connect');
  
      options.forEach((option) => {
        option.addEventListener('click', () => {
          const dataIndex = option.getAttribute('data-index');
  
          const items = section.querySelectorAll('.js-bg-img');
          items.forEach((item) => {
            const isMatch = item.getAttribute('data-index') == dataIndex;
            item.classList.remove('active');
  
            if(isMatch){
              item.classList.add('active');
            }
          });
        });
      });
    }
  };
  
  const DAG = {};
  DAG.body = document.querySelector('body');
  DAG.ESC_CODE = 27;
  DAG.siteContent = document.querySelector('.site-content');
  DAG.footer = document.querySelector('.page-footer');
  DAG.isIe11 = !!window.MSInputMethodContext && !!document.documentMode;
  DAG.cursorX = '';
  DAG.cursorY = '';
  
  if(DAG.isIe11){
    DAG.body.classList.add('ie11');
  }
  
  // **************GLOBAL-FUNCTIONS**************
  // changeCirles
  DAG.changeCircle = (circle, fillPercent) => {
      const interval = 10;
      const angleIncrement = 6;
      let angle = 0;
      const isPercentZero = fillPercent == 0;
      const isPercentFull = fillPercent == 360;
  
      if(isPercentFull){
        fillPercent = 365;
      }
  
      if(isPercentZero){
        fillPercent = 5;
      }
      circle.setAttribute("stroke-dasharray", `${fillPercent}, 20000`);
  };
  
  DAG.initPopup = (openBtn, closeBtn) => {
    DAG.initOpenBtn(openBtn);
    DAG.initCloseBtn(closeBtn);
  };
  
  DAG.initOpenBtn = (openBtn) => {
    openBtn.addEventListener('click', () => {
      const dataSelector = openBtn.getAttribute('data-selector');
      const popup = document.querySelector(`.${dataSelector}`);
  
      popup.classList.add('active');
      DAG.body.classList.add('overflow');
    });
  };
  
  DAG.initCloseBtn = (closeBtn) => {
    closeBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      const popup = closeBtn.closest('.js-popup');
  
      popup.classList.remove('active');
      DAG.body.classList.remove('overflow');
    });
  };
  
  DAG.initClosePopup = () => {
    document.documentElement.addEventListener('click', function (event) {
      const isClickWithinOpenedDiv = event.target.closest('.js-popup-content');
      if (!isClickWithinOpenedDiv) {
        const popups = document.querySelectorAll('.js-popup');
  
        popups.forEach((popup) => {
          popup.classList.remove('active');
        });
        DAG.body.classList.remove('overflow');
      }
    }, true);
  };
  
  DAG.popup = () => {
    const openBtns = document.querySelectorAll('.js-popup-open');
    const closeBtns = document.querySelectorAll('.js-popup-close');
  
    openBtns.forEach(DAG.initOpenBtn);
    closeBtns.forEach(DAG.initCloseBtn);
    DAG.initClosePopup();
  };
  
  // lexicon
  function declOfNum(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
  };
  
  // siblings
  var getSiblings = function (elem) {
    var siblings = [];
    var sibling = elem.parentNode.firstChild;
  
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling
    }
  
    return siblings;
  
  };
  
  // click outside elem
  function top_walker (node, test_func, last_parent) {
  	while ( node && node !== last_parent ) {
  		if ( test_func(node) ) {
  			return node;
  		}
  		node = node.parentNode;
  	}
  }
  
  // get coords
  function getCoords(elem) {
    let box = elem.getBoundingClientRect();
  
    return {
      top: box.top + pageYOffset,
      left: box.left + pageXOffset
    };
  }
  
  // get percent from number
  const getPercent = (num, per) => {
    return (num/100)*per;
  };
  
  const getPercentNumberFromNumber = (totalNumber, number) => {
    return (number/totalNumber)*100;
  };
  
  // get mouse position
  const getMousePosition = () => {
    window.addEventListener('mousemove', function (e) {
      DAG.cursorX = e.x;
      DAG.cursorY = e.y;
    });
  };
  
  const onPageRdy = () => {
    // utility
    getMousePosition();
    select();
    rangeSlider();
    tabs();
    pick();
    accordion();
    DAG.popup();
    phoneMask();
  
    // specific
    circleHover();
    search();
    menu();
    searchMenu();
    triangle();
    closeCookies();
    validateForm();
    connectSelect();
  
    // sliders
    mainSlider();
    tripleSlider();
    doubleSlider();
    contentSlider();
    bottomSlider();
  };
  
  onPageRdy();
  
